import { memo, useRef } from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import styles from './WorkExperience.module.scss';

import AnimatedLink from '@/components/AnimatedLink/AnimatedLink';

import sanitizer from '@/utils/sanitizer';

export type Props = {
  className?: string;
  title: string;
  company: {
    name: string;
    url: string;
  };
  timeString: string;
  image: {
    src: string;
    alt: string;
  };
  content: string;
};

function WorkExperience({ className, title, company: { name, url }, timeString, image: { src, alt }, content }: Props) {
  const mainRef = useRef(null);

  return (
    <div className={classnames(styles.WorkExperience, className, 'mb-16 tablet:mb-24 desktop:mb-36')} ref={mainRef}>
      <h3 className={classnames(styles.title, 'max-w-[60rem] desktop:mb-6')}>{title}</h3>
      <div className={classnames(styles.extra)}>
        <AnimatedLink
          className="block font-body-bold uppercase mb-[0.5em] text-secondary"
          link={url}
          text={name}
          isExternal
          withIcon
        />
        <p className="font-body-bold uppercase text-offText text-xs">{timeString}</p>
      </div>
      <div
        className={classnames(styles.content, 'tablet:mt-[1.5em] pr-32 tablet:pr-0 ml-[1em] tablet:ml-0 desktop:mt-0')}
        dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
      ></div>
      <div className={classnames(styles.image, 'h-32 w-64 relative image-wrapper tablet:mt-[1.5em] desktop:mt-0')}>
        <Image src={src} alt={alt} layout="fill" />
      </div>
    </div>
  );
}

export default memo(WorkExperience);
