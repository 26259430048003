import { useRef, memo } from 'react';
import classnames from 'classnames';
import { GraphQLClient } from 'graphql-request';

import Head from '@/components/Head/Head';
import Hero from '@/components/Hero/Hero';
import WorkExperience from '@/components/WorkExperience/WorkExperience';
import Heading from '@/components/Heading/Heading';

import { useCopy } from '@/hooks/use-copy';

type Props = {
  className: string;
  jobs: {
    dates: string;
    title: string;
    description: {
      html: string;
    };
    logo: {
      url: string;
    };
    url: string;
    companyName: string;
  }[];
  educations: {
    dates: string;
    title: string;
    description: {
      html: string;
    };
    logo: {
      url: string;
    };
    url: string;
    schoolName: string;
  }[];
  mainText: {
    pdfCvDownload: {
      url: string;
    };
  };
};

export async function getStaticProps() {
  const graphcms = new GraphQLClient('https://api-ca-central-1.graphcms.com/v2/ckyl7hha6018t01z631j5bp1o/master');

  const { mainText, jobs, educations } = await graphcms.request(
    `
    {
      jobs {
        dates
        title
        description {
          html
        }
        logo {
          url
        }
        url
        companyName
      }
      educations {
        dates
        title
        description {
          html
        }
        logo {
          url
        }
        url
        schoolName
      }
      mainText(where: {id: "ckytrvec82yx00b86d9euo8f5"}) {
        pdfCvDownload {
          url
        }
        title
      }
    }
    `
  );

  return {
    props: {
      mainText,
      jobs,
      educations
    }
  };
}

function Home({ className, mainText, jobs, educations }: Props) {
  const containerRef = useRef<HTMLElement>(null);
  const {
    global: { core }
  } = useCopy();

  return (
    <>
      <main className={classnames(className)} ref={containerRef}>
        <Head title={core.title} />
        <section className="width-wrapper">
          <Hero pdfLink={mainText.pdfCvDownload} />
        </section>

        <section id="work" className="width-wrapper scroll-my-[12rem] my-48 tablet:my-56 desktop:my-72">
          <Heading
            headingLevel={2}
            className="mb-8 tablet:mb-12 desktop:mb-16"
            text={'Work Experience'}
            iconType="work"
          />

          {jobs.map((job, index) => (
            <WorkExperience
              key={index}
              title={job.title}
              company={{
                name: job.companyName,
                url: job.url
              }}
              timeString={job.dates}
              image={{
                src: job?.logo?.url,
                alt: job?.companyName
              }}
              content={job?.description?.html}
            />
          ))}
        </section>

        <section id="education" className="width-wrapper scroll-my-[12rem] my-48 tablet:my-56 desktop:my-72">
          <Heading
            headingLevel={2}
            className="mb-8 tablet:mb-12 desktop:mb-16"
            text={'Education Experience'}
            iconType="education"
          />

          {educations.map((school, index) => (
            <WorkExperience
              key={index}
              title={school.title}
              company={{
                name: school.schoolName,
                url: school.url
              }}
              timeString={school.dates}
              image={{
                src: school.logo.url,
                alt: school.schoolName
              }}
              content={school?.description?.html}
            />
          ))}
        </section>
      </main>
    </>
  );
}

export default memo(Home);
