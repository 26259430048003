import { memo } from 'react';
import classnames from 'classnames';

import styles from './DownloadButton.module.scss';

export type Props = {
  className?: string;
  text: string;
  file: string;
};

function DownloadButton({ className, text, file }: Props) {
  return (
    <a
      className={classnames(styles.DownloadButton, 'py-4 px-6  uppercase font-body-bold text-xs', className)}
      download
      target="_blank"
      rel="noopener noreferrer"
      href={file}
    >
      <span>{text}</span>
    </a>
  );
}

export default memo(DownloadButton);
