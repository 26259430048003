import { memo } from 'react';
import classnames from 'classnames';

import AnimatedLink from '@/components/AnimatedLink/AnimatedLink';
import DownloadButton from '@/components/DownloadButton/DownloadButton';

export type Props = {
  className?: string;
  pdfLink: {
    url: string;
  };
};

function Hero({ className, pdfLink }: Props) {
  console.log(pdfLink);
  return (
    <div className={classnames(className, 'flex flex-col justify-center mt-60 mb-80')}>
      <h1 className="mb-20">Laura Amador</h1>
      <p className="max-w-[70rem] text-md desktop:text-lg mb-16">
        Currently working for{' '}
        <AnimatedLink
          className="font-semibold"
          text="Mondelez"
          link="http://www.mondelezinternational.com/"
          isExternal
        />{' '}
        as Project Engineer II.
        <br />
        I&apos;m a{' '}
        <AnimatedLink
          className="font-semibold"
          text="Chemical Engineering"
          link="https://uwaterloo.ca/chemical-engineering/"
          isExternal
        />{' '}
        grad from Waterloo University. <br />
        You can check out my <AnimatedLink className="font-semibold" text="work experience" link="#work" /> and {''}
        <AnimatedLink className="font-semibold" text="education" link="#education" />. <br />
        Previously,{' '}
        <AnimatedLink className="font-semibold" text="Bombardier" link="https://www.bombardier.com" isExternal />,{' '}
        <AnimatedLink className="font-semibold" text="ABC Group" link="https://abctechnologies.com/" isExternal /> and{' '}
        <AnimatedLink className="font-semibold" text="PepsiCo" link="http://pepsico.ca/" isExternal />. <br />I love
        travelling, coffee and Disney songs.
      </p>
      <DownloadButton className="self-start" text="CV Download" file={pdfLink.url} />
    </div>
  );
}

export default memo(Hero);
