import { memo } from 'react';
import classnames from 'classnames';

import styles from './Heading.module.scss';

const svgCommonClasses = {
  xmlns: 'http://www.w3.org/2000/svg',
  className: 'absolute h-7 w-7 text-text top-1/2 -translate-y-1/2 -right-16 desktop:-left-16',
  fill: 'none',
  viewBox: '0 0 24 24',
  stroke: 'currentColor'
};

const EducationIcon = (
  <svg {...svgCommonClasses}>
    <path d="M12 14l9-5-9-5-9 5 9 5z" />
    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
    />
  </svg>
);

const WorkIcon = (
  <svg {...svgCommonClasses}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
);

const VolunteeringIcon = (
  <svg {...svgCommonClasses}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
    />
  </svg>
);

interface IconMap {
  [name: string]: React.ReactNode;
}

const ICONS: IconMap = {
  education: EducationIcon,
  work: WorkIcon,
  volunteer: VolunteeringIcon
};

export type Props = {
  className?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  text: string;
  iconType?: keyof IconMap;
};

function Heading({ className, headingLevel = 1, text, iconType }: Props) {
  const Tag: keyof JSX.IntrinsicElements = `h${headingLevel}`;
  const icon = iconType ? ICONS[iconType] : null;

  return (
    <Tag className={classnames(styles.Heading, className)}>
      {icon}
      <span>{text}</span>
    </Tag>
  );
}

export default memo(Heading);
